@import "./_colours.scss";

#navbar-logo {
	width: 48px;
	height: 48px;
	margin-top: 6px;
	margin-left: 16px;
	margin-bottom: 4px;
}

.nav-group {
	margin-top: 24px;
}

.navbar {
	padding: 0.5rem 1rem 0 0.5rem !important;
}

.nav-link,
.nav-link-icon {
	cursor: pointer;
	color: $primary-3;
	margin: 0;
	padding: 8px 12px !important;
	transition: all 0.23s;
	display: block;

	text-decoration: none;
}

.nav-link:hover,
.nav-link-icon:hover {
	// color: rgba(0,0,0,0.7);
	color: $primary-2;
	// background-color: rgba(255,255,255,0.3);
	background-color: $primary-9;

	.navbar-counter {
		opacity: 1;
	}
}

.nav-link-active {
	// background-color: $secondary-colour !important;
	// color: rgba(0,0,0,0.9);
	color: $primary-1;
	background-color: $primary-9;
	// font-weight: bold;
	border-left: 4px solid $accent-dark;
}

.nav-item-icon {
	margin-right: 8px;
}

#need-help {
	font-style: bold;
	color: whitesmoke;
	background-color: $primary-4;
	border-radius: 15px;
	text-decoration: none;
	padding: 0 8px;
	transition: all 0.3s;
}

#need-help:hover {
	background: $primary-6;
	color: $primary-1;
}

@media (min-width: 990px) {
	.nav-item-icon-block {
		display: block;
	}
}

.navbar-count-icon {
	position: relative;
	float: left;
	.navbar-counter {
		transition: all 0.23s;
		position: absolute;
		right: -6px;
		top: -8px;
		background-color: $accent-dark;
		opacity: 0.9;
		color: white;
		font-size: 14px;
		font-weight: normal;
		padding: 0 4px;
		border-radius: 8px;
	}
}

.navbar-name {
	color: $primary-4;
}

@media (min-width: 990px) {
	.nav-link-icon {
		cursor: pointer;
		color: $primary-5;
		padding: 5px 0 0 5px !important;
		text-align: center;
		transition: all 0.23s;
		font-size: 20px;
	}

	.nav-link-icon:hover {
		color: $primary-3;
		background-color: unset;
	}

	.nav-icon-active {
		color: $primary-3 !important;
	}

	.navbar-name,
	#need-help {
		margin-top: 3px;
		margin-right: 12px;
	}
}

@media (max-width: 990px) {
	.navbar {
		display: block !important;
		text-align: right;
		padding-right: 0 !important;
	}

	.navbar-collapse {
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
		background-color: white;
		margin-bottom: 24px;
		padding: 8px 0;
		.navbar-nav {
			text-align: left;
		}

		.mobile-only {
			.nav-group {
				margin-top: 0;
				margin-bottom: 6px;
				padding-bottom: 4px;
				border-bottom: 1px solid #ddd;
			}
		}

		.nav-link,
		.nav-link-icon {
			padding: 4px 8px !important;
			color: $primary-6 !important;
		}

		.nav-link:hover,
		.nav-link-icon:hover {
			background-color: $primary-1;
			text-decoration: none;
		}

		.nav-link-active {
			background-color: $primary-1;
		}
	}

	.navbar-name,
	#need-help {
		display: block;
		text-align: left;
		margin: 5px 10px;
	}

	#need-help {
		padding: 2px 10px;
	}
}
