@import "./colours";

.rate-calculations-row {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    td {
        padding: 6px;
    }
}

.rate-calculations-label {
    color: $grey-6;
    font-size: 13px;
}

.rate-calculations-amount {
    text-align: right;
    font-weight: bold;
}
