@import './_colours.scss';
$sidebar-width: 247px;

h1 {
    margin-bottom: 24px !important;
}

.alert {
    transition: all 0.5s;
    font-size: 14px;
    svg {
        margin-right: 4px;
    }
}

.alert-visible {
    opacity: 1;
    // max-height: 140px;
    height: unset;
    padding: 0.75rem 1.25rem !important;
    margin-bottom: 1rem !important;
}

.alert-hidden {
    opacity: 0;
    // max-height: 0px;
    height: 0;
    padding: 0 1.25rem !important;
    margin-bottom: 0 !important;
}

#sidebar,
#main-content {
    float: left;
}

#sidebar {
    padding-top: 24px;
    width: $sidebar-width;
    background-color: $primary-8;
    height: 100vh;
    position: fixed;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.23);
}

#main-content {
    padding: 0 12px 0 32px;
    width: 100%;
    // min-height: 100vh;
    // height: 100vh;

    @media (max-width: 990px) {
        padding: 0 12px;
    }
}

.not-verified {
    max-width: 680px;

    svg {
        color: $primary-3;
        margin-top: 24px;
        margin-bottom: 32px;
    }
}

.system-alert {
    position: fixed;
    width: 100vw;
    top: 32px;
    left: 0;
    z-index: 100;

    .alert {
        margin: 0 auto;
        width: calc(100% - 60px);
        max-width: 900px;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.22);
    }
}

#back-btn {
    margin-bottom: 24px;
}

.cookie-modal {
    #back-btn {
        margin-bottom: 0;
    }
}

@media (min-width: 990px) {
    #main-content {
        width: calc(100% - #{$sidebar-width});
        margin-left: $sidebar-width;
    }
}

.time-marker {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 15px;
    line-height: 1em;
    color: $primary-6;
    margin-bottom: 6px;
    // margin-top: 12px;

    border-left: 4px solid $primary-3;
    background-color: $primary-2;
    padding: 8px 8px 8px 18px;
    border-radius: 5px;
}

.transition-enter {
    opacity: 0.01;
    max-height: 0;
    // transform: translate(-40px, 0)
}

.transition-enter-active {
    opacity: 1;
    max-height: 100px;
    // transform: translate(0, 0);
    transition: all 0.31s linear;
}

.transition-exit {
    opacity: 1;
    max-height: 100px;
    // transform: translate(0, 0)
}

.transition-exit-active {
    opacity: 0.01;
    max-height: 0;
    // transform: translate(40px, 0);
    transition: all 0.3s linear;
}

@media (max-width: 900px) {
    #main-content {
        width: 100%;
    }
}

.fa-layers {
    width: 100%;
    margin-bottom: 6px;
}

.fa-inverse {
    color: $grey-1;
}
