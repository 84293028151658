$primary-light-step: 11.2%;
$primary-dark-step: 5%;
$primary-saturate-step: 6%;

$primary-1: hsl(120, 36%, 93%);
$primary-2: hsl(120, 27%, 80%);
$primary-3: hsl(120, 22%, 70%);
$primary-4: hsl(120, 18%, 60%);
$primary-5: hsl(120, 15%, 45%);
$primary-6: hsl(125, 17%, 40%);
$primary-7: hsl(120, 21%, 33%);
$primary-8: hsl(120, 22%, 27%);
$primary-9: hsl(120, 24%, 22%);

// $grey-1: hsl(210, 36%, 96%);
// $grey-2: hsl(211, 26%, 82%);
// $grey-3: hsl(210, 23%, 72%);
// $grey-4: hsl(209, 19%, 59%);
// $grey-5: hsl(209, 15%, 48%);
// $grey-6: hsl(209, 18%, 43%);
// $grey-7: hsl(209, 20%, 36%);
// $grey-8: hsl(208, 29%, 25%);
// $grey-9: hsl(209, 61%, 16%);

$grey-1: hsl(0, 0%, 96%);
$grey-2: hsl(0, 0%, 82%);
$grey-3: hsl(0, 0%, 72%);
$grey-4: hsl(0, 0%, 59%);
$grey-5: hsl(0, 0%, 48%);
$grey-6: hsl(0, 0%, 43%);
$grey-7: hsl(0, 0%, 36%);
$grey-8: hsl(0, 0%, 25%);
$grey-9: hsl(0, 0%, 16%);

// $grey-1: hsl(40, 23%, 96%);
// $grey-2: hsl(43, 13%, 82%);
// $grey-3: hsl(40, 15%, 72%);
// $grey-4: hsl(40, 11%, 59%);
// $grey-5: hsl(40, 8%, 48%);
// $grey-6: hsl(40, 9%, 43%);
// $grey-7: hsl(40, 10%, 36%);
// $grey-8: hsl(40, 13%, 23%);
// $grey-9: hsl(42, 15%, 16%);

// $font-colour: hsl(120, 5%, 35%);
$font-colour: $primary-8;
// $background-colour: hsl(120, 35%, 98%);
$background-colour: $grey-1;
// $sidebar-background: hsl(120, 15%, 92%);

$btn-primary-colour: $primary-5;
$btn-primary-hover-colour: $primary-6;
$btn-secondary-colour: hsl(120, 10%, 67%);
$btn-secondary-hover-colour: hsl(120, 8%, 60%);

$primary-colour: hsl(120, 10%, 45%);
$secondary-colour: hsl(120, 20%, 72%);
$secondary-colour-light: hsl(120, 34%, 92%);

$font-colour-light: hsl(120, 10%, 70%);
$input-bg: rgb(201, 224, 201);
$accent-dark: hsl(20, 100%, 50%);
$accent-light: hsl(20, 100%, 72%);

$tbl-border-colour: hsl(120, 48%, 87%);
$box-colour: hsl(127, 40%, 92%);

.swatches {
    padding-top: 180px;
	width: 700px !important;
    
    td {
        border: 16px solid white;
        height: 64px;
        border-radius: 48px;
    }
}

.primary-1 {
	background-color: $primary-1;
}
.primary-2 {
	background-color: $primary-2;
}
.primary-3 {
	background-color: $primary-3;
}
.primary-4 {
	background-color: $primary-4;
}
.primary-5 {
	background-color: $primary-5;
}
.primary-6 {
	background-color: $primary-6;
}
.primary-7 {
	background-color: $primary-7;
}
.primary-8 {
	background-color: $primary-8;
}
.primary-9 {
	background-color: $primary-9;
}



.grey-1 {
	background-color: $grey-1;
}
.grey-2 {
	background-color: $grey-2;
}
.grey-3 {
	background-color: $grey-3;
}
.grey-4 {
	background-color: $grey-4;
}
.grey-5 {
	background-color: $grey-5;
}
.grey-6 {
	background-color: $grey-6;
}
.grey-7 {
	background-color: $grey-7;
}
.grey-8 {
	background-color: $grey-8;
}
.grey-9 {
	background-color: $grey-9;
}
