@import './colours';

$datetime-width: 128px;
$btns-width: 210px;
$money-width: 80px;
$indicator-width: 8px;

.list-item-container {
    table {
        table-layout: fixed;
    }

    clear: both;
    display: block;

    .list-item {
        // padding: 10px 8px;

        table {
            width: 100%;
        }

        background-color: rgba(255, 255, 255, 0);
        transition: all 0.4s;

        .list-item-datetime {
            width: $datetime-width;
            padding-right: 24px;
            text-align: center;

            .list-item-date {
                font-weight: bold;
                font-size: 16px;
                line-height: 18px;
            }

            .list-item-time {
                // font-weight: bold;
                opacity: 0.8;
                font-size: 14px;
                line-height: 18px;
            }

            .list-item-category {
                opacity: 0.6;
                color: $primary-5;
                margin-top: 2px;
                font-size: 13px;
                line-height: 16px;
            }
        }

        .list-item-money {
            text-align: left;
            width: $money-width;

            .list-item-money-rate {
                opacity: 0.6;
                font-size: 14px;
            }
        }

        .list-item-btns {
            padding-left: 24px;
            width: $btns-width;
            text-align: right;
        }

        $availability-colour: hsl(133, 51%, 77%);
        $incoming-colour: hsl(133, 62%, 33%);
        $outgoing-colour: hsl(49, 83%, 67%);
        $upcoming-colour: #77baff;
        $completed-colour: white;
        $cancelled-colour: hsl(0, 56%, 87%);
        $declined-colour: rgba(0, 0, 0, 0.1);

        .agenda-colour {
            margin-top: 8px;
            float: left;

            height: 44px;
            width: #{$indicator-width - 3px};
            margin-right: 3px;
            border-radius: 4px 0 0 4px;

            // background-color: rgba(0, 0, 0, 0.1);
        }

        .availability,
        .incoming,
        .outgoing,
        .upcoming,
        .completed,
        .cancelled,
        .declined {
            box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
        }

        .availability {
            background-color: $availability-colour;
        }

        .incoming {
            background-color: $incoming-colour;
        }

        .outgoing {
            background-color: $outgoing-colour;
        }

        .upcoming {
            background-color: $upcoming-colour;
        }

        .completed {
            background-color: $completed-colour;
        }

        .cancelled {
            background-color: $cancelled-colour;
        }

        .declined {
            background-color: $declined-colour;
        }
    }

    .list-item-disabled {
        opacity: 0.6;
        background-color: #eee;
    }

    .list-item-cancelled span {
        opacity: 0.8;
        color: red;
        text-decoration: line-through;
    }

    .list-item-declined span {
        opacity: 0.8;
        text-decoration: line-through;
    }
}

.list-item-container::after,
.list-item-row::after,
.list-item-profile-body::after {
    content: '';
    clear: both;
    display: table;
}

.list-item-container + .time-marker {
    margin-top: 12px;
}

.list-item-row {
    padding: 10px 8px;
}

.list-item-row > div {
    float: left;
}

.list-item-body-clickable {
    cursor: pointer;
}

.list-item-body-clickable:hover {
    text-decoration: underline;
}

.list-item-clickable {
    background-color: $primary-1;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    border-left: 4px solid $accent-light;
    position: relative;
    border-radius: 3px;
    z-index: 100;
    transition: all 0.3s;
}

.list-item-clickable-active {
    // background-color: $accent-light;
    // color: white;
    z-index: 1000;
    // box-shadow: 0 4px 8px rgba(0,0,0,0.3);
    border-radius: 3px;
}

.btn,
.list-item-name > a {
    padding: 8px 16px;
    border-radius: 6px;
    transition: all 0.2s;
    text-decoration: none;
}

.list-item-name > a {
    // box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    border: 1px solid $grey-2;
    // color: white;
    color: $primary-5;
    // background-color: $grey-5;
    display: inline-block;
    margin-top: -8px;
    margin-right: 12px;
    margin-bottom: 8px;
}

.list-item-name > a:hover {
    text-decoration: none;
    border: 1px solid $primary-3;
    color: $primary-5;
    background-color: $primary-2;
}

.list-item-name {
    float: left;
    padding-top: 6px;
}

.list-item-body {
    float: left;
}

.list-matches {
    margin: 0 8px 12px 8px;
    padding: 8px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: relative;
}

.list-additional-hours {
    width: 160px !important;
    margin-right: 24px;
    // margin-bottom: 0 !important
}

.strike-off {
    white-space: nowrap;
    opacity: 0.8;
    text-decoration: line-through;
    color: red;
}

.no-entries-found {
    color: $primary-3;
    // font-style: italic;
    padding: 24px;
    text-align: center;
    width: 100%;
    margin-top: 24px;
}

.no-entries-blurb {
    margin: 36px auto 0 auto;
    max-width: 500px;
    font-size: 17px;

    .no-entries-header {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 8px;
        color: $primary-4;
    }
}

$name-width-lg: 260px;

@media (min-width: 1150px) {
    .list-item-name {
        width: $name-width-lg;
    }

    .list-item-profile-body {
        width: calc(100% - #{$datetime-width + $btns-width + $money-width + $indicator-width});
    }
    .list-item-body {
        width: calc(100% - #{$name-width-lg});
    }
}

@media (min-width: 990px) and (max-width: 1149px) {
    .list-item-profile-body {
        width: calc(100% - #{$datetime-width + $btns-width + $money-width + $indicator-width});
        div {
            clear: both;
        }
    }
    .list-item-body {
        margin-top: 8px;
    }
}

@media (max-width: 989px) {
    .list-item-container {
        background-color: rgba(255, 255, 255, 0.6);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        // border: 1px solid $grey-2;
        border-radius: 5px;
        margin: 12px 0 24px 0;
    }

    .list-item-row {
        padding: 28px 16px 16px 16px;
    }

    .list-item-container + .list-item-container {
        // border-top: 1px solid $grey-2;
    }

    .agenda-colour {
        margin-top: 0 !important;
        height: 12px !important;
        width: 100% !important;
        border-radius: 4px 4px 0 0 !important;
        margin-bottom: 12px;
        // border-bottom: 1px solid $grey-2;
    }

    .list-item-datetime {
        text-align: left !important;
        width: 100% !important;
        margin-bottom: 8px;
    }

    .list-item-name {
        margin-bottom: 2px;
        a {
            padding: 4px 10px;
            display: block;
        }
    }
    .list-item-profile-body {
        width: 100%;
        .list-item-profile,
        .list-item-body {
            width: 100%;
        }
    }
    .list-item-money,
    .list-item-btns {
        width: 100% !important;

        font-size: 16px;
        font-weight: bold;
        margin-top: 8px;
    }
    .list-item-money {
        div {
            display: inline;
            margin-right: 6px;
        }
    }
    .list-item-btns {
        // text-align: left !important;
        margin-top: 18px;
        padding-left: 0 !important;
        margin-left: 0 !important;
        width: 100% !important;
    }
}
