@import "./colours";

#edit-profile {
	width: 100%;

	table {
		width: 100%;
	}

	.data-field {
		// cursor: pointer;
		// border-top: 1px solid $secondary-colour;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		// border-bottom: 1px solid $secondary-colour;
		// padding: 8px 8px;
		margin-top: -1px;
		transition: all 0.3s;
		width: 100%;

		min-height: 52px;

		td {
			padding: 8px;
		}

		.field-name {
			font-weight: bold;
			color: $secondary-colour;
			width: 140px;
		}

		.field-value {
			white-space: pre-wrap;
		}

		.field-value-not-set {
			opacity: 0.5;
			// font-style: italic;
		}

		.field-btn {
			text-align: right;
			width: 40px;
		}
	}

	h2 {
		margin-bottom: 18px;
		margin-top: 32px;
	}

	.list-item-name {
		height: 26px;
		margin-left: 4px;
		width: 100%;
		a {
			padding: 3px 8px;
			font-size: 14px;
			border-radius: 3px;
			display: inline !important;
		}
	}

	.practice-row {
		width: 100%;
		margin-bottom: 12px;
		a {
			font-size: 16px;
		}
		select {
			margin-right: 4px;
		}

		height: unset;
	}

	.btn-primary {
		margin-top: 24px;
	}

	.borough-privacy {
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);

		h3 {
			font-size: 20px;
			margin-bottom: 8px;
		}

		.borough-privacy-default {
			background-color: $primary-1;
			padding: 10px;
			border-radius: 6px;
			margin-bottom: 18px;
		}

		.borough-privacy-individuals::after {
			content: "";
			clear: both;
			display: table;
		}
	}
}
