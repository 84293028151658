@import './colours';

#noticeboard {
    width: 100%;
}

.notice-input-form {
    width: 100%;
    background-color: $primary-2;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 16px;

    text-align: right;
    margin-bottom: 48px;

    input,
    textarea {
        width: 100%;
        border: 0;
        background-color: white;
        margin-bottom: 6px;
        margin-top: 0;
        padding: 4px 8px;
        display: block;
        // box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    }

    textarea {
        height: 200px;
    }

    .notice-input-form-header {
        font-size: 18px;
        font-weight: bold;
    }
}

#noticeboard-notices {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.notice {
    background-color: white;
    // width: calc(33% - 16px);
    // min-width: 300px;
    // max-width: calc(50% - 16px);
    // width: 100%;
    padding: 10px 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    // float: left;
    margin: 8px;
    // margin: 12px 0;

    // flex: 1;

    position: relative;

    .notice-header {
        font-weight: bold;
        font-size: 18px;
    }

    .notice-body {
        white-space: pre-wrap;
    }

    .notice-footer {
        font-size: 14px;
        text-align: right;
        font-style: italic;
        color: $grey-3;
    }

    .notice-delete {
        position: absolute;
        top: 6px;
        right: 10px;
        opacity: 0.3;
        transition: all 0.3s;
        cursor: pointer;
    }

    .notice-delete:hover {
        opacity: 0.15;
    }
}

@media (max-width: 990px) {
    #noticeboard-notices {
        flex-direction: column;
    }

    .notice {
        width: 100%;
        margin: 8px 0;
    }
}
