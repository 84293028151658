@import './colours';

.loading {
    text-align: center;
    margin: 24px 0;
    line-height: 36px;
    width: 100%;
    color: $grey-3;
}

.fa-spin {
    -webkit-animation: fa-spin 1.1s infinite linear;
    animation: fa-spin 1.1s infinite linear;
  }