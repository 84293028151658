@import './colours';

$input-height: 100px;
$input-padding: 14px;

// #message-header {
// 	margin-top: -18px;
// 	display: block;
// 	align-items: center;

// 	h2 {
// 		display: inline;
// 		margin: 0 0 0 18px;
// 	}
// }

#view-message .loading,
#message-list {
    height: calc(100% - #{$input-height} - 28px);
}

#view-message > .loading {
	margin: 0;
}

#view-message {
    height: calc(100% - 186px);
    // height: 100px;
}

#message-list {
	height: calc(100vh - #{$input-height + 250px});
	overflow-y: auto;

	.message {
		background-color: white;
		border: 1px solid rgba(0, 0, 0, 0.06);
		border-radius: 6px;
		padding: 8px 12px;
		margin-top: 6px;
		margin-bottom: 4px;
		margin-right: 8px;
		width: 60%;

		float: left;

		.message-timestamp {
			font-size: 12px;
			opacity: 0.7;
			float: right;
			margin-top: 4px;
		}

		.message-header {
			color: $primary-4;
			margin-bottom: 4px;
		}
	}

	.message:after {
		content: "";
		display: table;
		clear: both;
	  }

	.message-out {
		float: right;
	}

	.message-in {
		background-color: $secondary-colour-light;
	}

	.message-out + .message-out,
	.message-in + .message-in {
		margin-top: 0;
	}
}

#message-input {
	padding: $input-padding 0 0 0;
	textarea {
		height: $input-height;
	}
	.btn {
		margin: 0;
	}
}

@media screen and (max-width: 900px) {
	#message-list {
		.message {
			width: 84%;
		}
	}
}