$profile-width: 280px;
$profile-margin: 64px;
$messages-width: 400px;

$header-height: 240px;

@import './ViewMessages.scss';
@import './colours.scss';

#profile {
    #details,
    #past-jobs,
    #messages {
        float: left;
    }

    #details {
        width: $profile-width;
        padding-right: calc($profile-margin/2);
        margin-right: calc($profile-margin/2);
        border-right: 1px solid $primary-2;
        height: calc(100vh - #{$header-height});

        #detail-rows {
            margin: 28px 0;
            color: $primary-5;
            font-size: 16px;

            .profiles-icon {
                color: $primary-3;
            }
        }

        .details-bio {
            white-space: pre-wrap;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            padding: 6px 12px;
            margin: 18px 0;
            background-color: rgba(0, 0, 0, 0.01);
            box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 2px hsla(0, 0%, 0%, 0.07);
        }
    }

    h3 {
        margin: 32px 0 16px 0;
    }

    #past-jobs {
        width: calc(100% - calc($profile-width + $profile-margin / 2));
        #job-list-max-height {
            height: calc(100vh - #{$header-height + 68px});
            overflow-y: auto;
        }
    }
}

@media (max-width: 1450px) {
    #profile {
        #details,
        #past-jobs {
            width: 100% !important;
            border: 0;
            height: unset;
        }

        #past-jobs {
            border-top: 3px solid $primary-1;
            margin-top: 12px;
            padding-top: 24px;
        }

        #details {
            .details-bio {
                height: unset;
            }
        }
    }
}
