$card-margin: 8px;
$card-height: 150px;
$card-width: calc(33% - #{$card-margin * 2});

$card-width-mid: calc(50% - #{$card-margin * 2});
$card-width-small: 100%;

$pic-size: 90px;

#profiles {
    .profiles {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 16px;

        @media (max-width: 1700px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 1300px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 1000px) {
            grid-template-columns: 1fr;
        }

        margin-bottom: 32px;
    }

    .profiles-card {
        display: block;
        box-sizing: border-box;
        background-color: #fdfdfd;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: 0.4s all;
        border-radius: 5px;
        text-decoration: none;

        .profiles-profile-body {
            padding: 10px 16px;

            .profiles-name {
                color: black;
                font-weight: bold;
                font-size: 17px;
            }

            .profiles-body {
                font-size: 14px;
                color: #999;

                .profiles-icon {
                    color: #bbb;
                }
            }
        }
    }

    .profiles-card:hover {
        background-color: white;
        box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
    }

    .practice-ccg + .practice-ccg {
        margin-top: 32px;
    }

    .practice-borough h2 {
        margin-top: 18px;
    }

    .practice-borough::after {
        content: '';
        clear: both;
        display: table;
    }
}