@import './colours' ;

.legal-doc {
    padding-top: 32px;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;

    button { 
        margin-left: 12px;
    }

    h2 {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        margin: 28px 0 12px 0;
        letter-spacing: 1px;
        color: $primary-1;
        border-left: 4px solid $primary-3;
        padding: 8px 14px;
        background: $primary-6;
    }

    div {
        padding: 12px;

        li {
            margin-bottom: 6px;
            padding-left: 4px;
        }
    }

    table {
        width: 100%;
        th {
            background-color: $grey-2;
        }
        th, td {
            padding: 4px 6px;
            border: 1px solid grey;
        }
        margin-bottom: 12px;
    }
}
