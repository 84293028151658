@import '../../styles/_colours.scss';

#compliance-docs {
  margin: 24px;

  .file-row {
    padding: 12px 0;
  }

  .file-row + .file-row {
    border-top: 1px solid $grey-2;
  }

  .flex-1 {
    flex: 1;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
    align-items: center;
  }

  .row + .row {
      margin-top: 8px;
  }
}

.compliance-doc-upload-model {
  .link {
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.7;
  }
}