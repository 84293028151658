@import './colours';

$cal-month-width: 300px;
$agenda-cell-height: 70px;
$agenda-hour-width: 50px;

@media (max-width: 989px) {
    .cal-month,
    .cal-agenda {
        width: 100%;
    }

    .cal-agenda {
        margin-top: 32px;
    }
}

@media (min-width: 990px) {
    .cal-month {
        width: $cal-month-width;
    }

    .cal-agenda {
        margin-left: 48px;
        width: calc(100% - #{$cal-month-width + 48px});
    }
}

$btn-width: 30px;

.cal-header {
    margin-bottom: 12px;
    width: 100%;
    height: 40px;

    .cal-header-btn {
        cursor: pointer;
        opacity: 0.6;
        transition: opacity 0.5s;
        padding: 8px;
        float: left;
        width: $btn-width;
    }

    .cal-header-btn:hover {
        opacity: 0.4;
    }

    .cal-header-text {
        float: left;
        text-align: center;
        width: calc(100% - calc($btn-width * 2));
    }
}

.cal-month-view {
    padding: 0;
}

.cal-month-header {
    margin-bottom: 8px;
    padding: 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.cal-month-header::after {
    content: '';
    clear: both;
    display: table;
}

.cal-month-week {
    .cal-month-dow {
        width: 14.2857%;
        float: left;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
    }

    .cal-month-cell {
        height: 40px;
        padding: 3px 6px;
        cursor: pointer;
        width: 14.2857%;
        float: left;

        .cal-cell-date {
            width: 32px;
            height: 32px;
            text-align: center;
            padding-top: 5px;
            border-radius: 16px;
            transition: background-color 0.5s;
        }
    }

    .cal-month-cell:hover {
        .cal-cell-date {
            background-color: $accent-light;
        }
    }

    .cal-month-cell,
    .cal-month-dow {
        flex: 1;
    }
}

.cal-fade {
    opacity: 0.33;
    background-color: rgba(0, 0, 0, 0.1);
}

.cal-today {
    .cal-cell-date {
        // font-weight: bold;
        background-color: $accent-dark;
        color: white;
    }
}

.cal-agenda-layout {
    height: calc(100vh - 200px);
    overflow-y: auto;
    padding-top: 18px;
    position: relative;
}

.cal-agenda-item {
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 4px 6px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.13);
    font-size: 14px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    transition: all 0.4s;
    text-decoration: none;
    color: $font-colour;
    overflow-y: hidden;

    .cal-agenda-item-address {
        opacity: 0.7;
    }

    .cal-agenda-item-roles {
        opacity: 0.7;
        font-style: italic;
    }
}

.cal-agenda-item:hover {
    color: $font-colour;
    text-decoration: none;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

.cal-agenda-item-availability {
    background-color: rgb(180, 230, 184);
}

.cal-agenda-item-incoming {
    background-color: rgb(238, 217, 172);
}

.cal-agenda-item-pending {
    background-color: rgb(216, 216, 216);
}

.cal-agenda-hour {
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    height: $agenda-cell-height;
}

.cal-agenda-layout-row {
    display: block; // flex!!
    flex-direction: row;

    .cal-agenda-layout-hour {
        height: $agenda-cell-height;
        margin-top: -6px;
        width: $agenda-hour-width;
        font-size: 12px;
        line-height: 12px;
        opacity: 0.6;
        text-align: center;
    }

    .cal-agenda-layout-box {
        height: $agenda-cell-height;
        border-top: 1px solid rgba(0, 0, 0, 0.07);
        width: 100%;
    }
}

$cal-header-height: 260px;

#dashboard {
    .cal-header {
        width: 100%;
        margin-top: 18px;
    }
    #agenda-view {
        width: 100%;
        height: calc(100vh - 357px) !important;
    }
}

#agenda-view > table {
    width: 100%;
}

#calendar {
    width: 100%;
    #month-view {
        width: 100%;

        th {
            text-align: center;
            padding: 8px;
        }

        th,
        td {
            border: 1px solid $grey-2;
        }

        .cal-cell {
            height: calc(16.6vh - #{$cal-header-height / 6});
            vertical-align: top;
            .cal-cell-date {
                text-align: right;
                font-size: 14px;
                padding: 4px;
            }
        }
    }

    #agenda-view {
        width: 100%;
        height: calc(100vh - 220px);
        overflow-y: auto;

        .agenda-day-heading {
            text-transform: uppercase;
            font-weight: bold;
            text-align: right;
            vertical-align: top;
            padding-top: 20px;
            padding-right: 14px;
            border-bottom: 1px solid $grey-2;
            border-left: 0;
            border-top: 0;

            .agenda-day-heading-dow {
                color: $primary-6;
                font-size: 14px;
                letter-spacing: 4px;
                line-height: 14px;
            }

            .agenda-day-heading-date {
                color: $primary-4;
                font-size: 12px;
                letter-spacing: 2px;
                white-space: nowrap;
            }
        }

        .agenda-day-items {
            border-bottom: 1px solid $grey-2;
            border-right: 0;
            border-top: 0;
        }
    }
}
