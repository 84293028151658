.notif-row:nth-child(even) {
	background-color: rgba(0, 0, 0, 0.04);
}

.notif-options {
	td,
	th {
		padding: 2px 10px;
	}
}
