@import "./colours";

.info-button {
    svg {
        margin-left: 4px;
        color: #709ef3;
        cursor: pointer;
        transition: 0.4s all;
    }

    svg:hover {
        color: #3362b9;
    }

    .info-button-text {
        background-color: $grey-1;
        border-radius: 6px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.23);

        padding: 0px 8px;

        font-size: 13px;

        height: 0;
        opacity: 0;
        transition: 0.3s all;
    }

    .info-button-text-showing {
        height: unset;
        padding: 6px 8px;
        opacity: 1;
        margin-top: 6px;
        margin-bottom: 10px;
    }
}
