@import './colours';

#messages-list {
    width: 100%;
}

.messages-message-row {
    background-color: rgba(255,255,255,0.6);
    padding: 12px;
    color: black;

    border: 1px solid rgba(0,0,0,0.1);
    margin-bottom: -1px;

    cursor: pointer;
    transition: all 0.3s;

    td {
        padding: 12px;
    }

    .messages-message-name {
        width: 200px;
        padding-left: 12px;
    }

    .messages-message-content {
        white-space: nowrap;
        overflow-x: hidden;
        margin-right: 24px;
        opacity: 0.6;
    }

    .messages-message-timestamp {
        width: 148px;
        opacity: 0.8;
        font-size: 14px;
    }
}

.messages-message-row-unread {
    background-color: $secondary-colour-light;
    font-weight: bold;
}

.messages-message-row:hover {
    // background-color: white;
    box-shadow: 0 0 6px rgba(0,0,0,0.3);
    z-index: 100;
    text-decoration: none;
    color: black;
}