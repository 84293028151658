@import './colours';

#notifications-window {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    width: 320px;

    position: absolute;
    top: 48px;
    right: 24px;

    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.12);

    z-index: 1000;

    .notification {
        padding: 5px 9px;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.3s;
        display: block;
        color: $font-colour;
        // border-radius: 3px;
        text-decoration: none;

        .notification-timestamp {
            font-size: 11px;
            opacity: 0.7;
            text-align: right;
            margin-top: 2px;
        }
    }

    .notification-unread {
        background-color: $secondary-colour-light;
    }

    .notification + .notification {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .notification:first-of-type {
        border-radius: 3px 3px 0 0;
    }

    .notification:last-of-type {
        border-radius: 0 0 3px 3px;
    }

    .notification:hover {
        background-color: $primary-1;
        color: $primary-9;
        text-decoration: none;
    }
}
