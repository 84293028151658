@import "./_colours.scss";

.btn {
	font-size: 14px;
	// text-transform: uppercase;
	// font-weight: bold;
	letter-spacing: 1px;
	padding: 10px 24px;
	margin: 2px;
}

.btn:disabled,
.btn:disabled:hover {
	background-color: $grey-2 !important;
	border-color: $grey-3 !important;
	cursor: not-allowed;
}

.btn-primary,
.btn-outline-primary {
	border-color: $btn-primary-colour;
}

.btn-primary {
	background-color: $btn-primary-colour;
}

.btn-outline-primary {
	color: $btn-primary-colour;
}

.btn-secondary {
	background-color: $btn-secondary-colour;
	border-color: $btn-secondary-colour;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus {
	background-color: $btn-primary-hover-colour !important;
	border-color: $btn-primary-colour !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
	background-color: $btn-secondary-hover-colour !important;
	border-color: $btn-secondary-colour !important;
}

.icon-btn {
	padding: 10px;
	margin: 3px;
}

.full-btn {
	width: 100%;
	margin-bottom: 3px;

	svg {
		margin-right: 8px;
	}
}

.btn-tertiary {
	color: $primary-7;
	border: 1px solid rgba(0, 0, 0, 0.13);
}

.btn-tertiary:hover {
	color: $primary-6;
	background-color: rgba(0, 0, 0, 0.07);
}

.btn-light {
	background-color: $grey-2;
}

.btn-light:hover {
	background-color: $grey-3;
}

.btn-secondary-danger {
	color: rgb(220, 53, 69);
	border: 1px solid rgb(220, 53, 69);
}

.btn-secondary-danger:hover {
	background-color: rgba(220, 53, 69, 0.07);
	color: rgba(220, 53, 69, 0.8);
}

.add-new-btn {
	margin-bottom: 32px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
	width: 100%;
}

.form-control-bio {
	height: 300px !important;
}

.form-control-time {
	font-family: "Open Sans", sans-serif;
	font-size: 14px;
}

select {
	border-radius: 4px;
	padding: 3px 4px;
	background-color: #fdfdfd;
	box-shadow: inset 0 0px 3px rgba(0, 0, 0, 0.1), inset 0 2px 2px rgba(0, 0, 0, 0.03);
}
