@import './_colours.scss';

body,
html {
    color: $font-colour;
    background-color: $background-colour;
    font-family: 'Karla', sans-serif;
    font-size: 14px;
}

html {
    height: 100%;
}

body,
#root {
    position: relative;
    min-height: 100vh;
}

#root {
    padding-bottom: 64px;
}

#root::after,
#root > div > div::after,
#main-content::after {
    content: '';
    clear: both;
    display: table;
}

h1 {
    font-size: 36px;
    padding-bottom: 8px;
    // font-weight: 700;
    // text-transform: uppercase;
    // letter-spacing: 4px;
    font-family: 'Merriweather', sans-serif;
    cursor: default;
}

h2 {
    color: $primary-5;
    font-size: 24px;
}

h3 {
    color: $primary-4;
    font-size: 20px;
}

.mobile-only {
    display: none;
}

.light-text {
    color: rgba(0, 0, 0, 0.6);
}

// .role {
// text-decoration: underline dashed rgba(0, 0, 0, 0.25);
// text-underline-position: under;
// cursor: help;
// }

a {
    color: $primary-5;
    text-decoration: underline;
}

@media only screen and (max-width: 990px) {
    .mobile-only {
        display: unset;
    }
    .desktop-only {
        display: none;
    }
}

.matches-found-text {
    margin-top: 4px;
    font-weight: bold;
}

.no-matches-found,
.job-status-text {
    font-style: italic;
    opacity: 0.6;
    font-weight: normal;
}

.no-wrap {
    white-space: nowrap;
}

.alert {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

    .alert-content {
        width: calc(100% - 14px);
        display: inline-block;
    }

    .alert-dismiss {
        opacity: 0.6;
        cursor: pointer;
        transition: all 0.3s;
        position: absolute;
    }

    .alert-dismiss:hover {
        opacity: 0.4;
    }
}
