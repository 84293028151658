@import './colours';

$box-margin: 8px;

#dashboard {
    h2 {
        margin-top: 24px;
        margin-bottom: 12px;
        font-weight: bold;
    }

    .alert {
        margin-top: 6px;
        margin-bottom: 2px;
        width: calc(100% - 8px);
        white-space: pre-wrap;
    }

    #noticeboard {
        max-height: 300px;
        overflow: scroll;
    }
}

#info-cells {
    width: 100%;

    .info-cell {
        background-color: white;
        width: calc(20% - #{$box-margin});
        float: left;
        margin: 0 $box-margin $box-margin 0;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
        padding: 8px 0;
        color: $font-colour;
        text-decoration: none;

        border-radius: 4px;

        .info-cell-header,
        .info-cell-body {
            padding: 0 14px;
        }

        .info-cell-header {
            opacity: 0.7;
            border-bottom: 1px solid #eee;
            padding-bottom: 8px;
        }

        .info-cell-body {
            font-size: 36px;
            font-weight: bold;
            padding-top: 18px;
            padding-bottom: 48px;

            .info-cell-icon,
            .info-cell-value {
                line-height: 36px;
            }

            .info-cell-icon {
                color: $secondary-colour;
                float: left;
            }

            .info-cell-value {
                text-align: right;
                float: right;
                padding-right: 8px;
            }
        }
    }

    .info-cell:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    }
}

@media (min-width: 990px) {
    #dashboard {
        .row {
            margin: 0;
        }

        .cal-agenda {
            width: 25%;
            margin-left: 0;
            padding-left: 24px;
        }
    }
}

@media (max-width: 990px) {
    #dashboard {
        padding: 0 12px;
    }

    #info-cells {
        width: 100%;

        .info-cell {
            width: calc(50% - #{$box-margin});
        }
    }
}
