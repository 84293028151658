@import "./colours";

#secondary-nav::after {
    content: "";
    clear: both;
    display: table;
}

#secondary-nav {
    width: 100%;
    justify-content: center;

    margin-bottom: 24px;

    .secondary-nav-item {
        color: rgba(0, 0, 0, 0.5);
        padding: 0px 8px 10px 8px;
        margin: 0 8px;
        font-weight: bold;
        border-bottom: 2px solid rgba(0, 0, 0, 0);
        opacity: 0.55;
        transition: all 0.4s;
        white-space: nowrap;
        display: block;
        float: left;
        text-decoration: none;
    }

    .secondary-nav-item:hover {
        // border-bottom: 2px solid $accent-dark;
        opacity: 1;
        text-decoration: none;
    }

    .secondary-nav-item-active {
        opacity: 1;
        border-bottom: 2px solid $accent-dark !important;
    }
}

@media (max-width: 990px) {
    #secondary-nav {
        .secondary-nav-item {
            padding-bottom: 4px;
            margin-bottom: 12px;
            border-bottom: 2px solid rgba(0, 0, 0, 0.2);
        }
    }
}
