#landing-page {
	width: 100%;
	padding-top: 50px;

	table {
		width: 100%;
    }
    
    #landing-page-header {
        text-align: center;
    }
}

#landing-page > div {
	width: 90vw;
	max-width: 800px;
    margin: 0 auto;
}

h1 {
	margin-bottom: 52px !important;
}

.auth-box-row {
	max-width: 800px;

	h2 {
		margin-bottom: 18px;
	}

	margin-top: 48px;
	// margin-bottom: 10vh;
}

.auth-box {
	width: 50%;
	padding: 0 60px;
    text-align: center;
    float: left;
}

#logo {
	max-width: 80px;
	max-height: 80px;
	margin-bottom: 18px;
}

.submit-btn {
	margin-top: 24px !important;
}

.inline-alert {
	margin: 6px 0 !important;
	padding: 6px 1.25rem !important;
}

.change-pw-visibility-btn {
	cursor: pointer;
	transition: background-color 0.3s;
}

.change-pw-visibility-btn:hover {
	background-color: #ccc;
}
