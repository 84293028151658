@import './colours';

#footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 8px 12px;
    // background-color: $grey-2;
    text-align: center;
    color: $grey-3;

    a {
        padding: 0 8px;
        font-size: 14px;
        color: $grey-5;
    }
}
